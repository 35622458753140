import {
  EntitySwitch,
  isKind
} from '@backstage/plugin-catalog';
import React from 'react';


import { apiPage } from './pages/ApiPage';
import { componentPage } from './pages/ComponentPage';
import { defaultEntityPage } from './pages/DefaultEntityPage';
import { domainPage } from './pages/DomainPage';
import { groupPage } from './pages/GroupPage';
import { systemPage } from './pages/SystemPage';
import { userPage } from './pages/UserPage';


export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

// function scaffolderList(params:type) {
//   const scaffolderApi = useApi(scaffolderApiRef);

//   catalogApi.getEntities().then(value => {
//     console.log(value.items);
//   })

//   if (scaffolderApi.listTasks != undefined) {
//     scaffolderApi.listTasks({ filterByOwnership: "all" }).then(value => {
//       console.log(value.tasks);
//     })
//   }
// }