import { EntityAdrContent, isAdrAvailable } from "@backstage-community/plugin-adr";
import { isGithubActionsAvailable } from "@backstage-community/plugin-github-actions";
import { isVaultAvailable } from "@backstage-community/plugin-vault";
import { EntityConsumedApisCard, EntityProvidedApisCard } from "@backstage/plugin-api-docs";
import { EntityDependsOnComponentsCard, EntityDependsOnResourcesCard, EntityLayout } from "@backstage/plugin-catalog";
import { isKubernetesAvailable } from "@backstage/plugin-kubernetes";
import { EntityGitlabContent, isGitlabAvailable } from "@immobiliarelabs/backstage-plugin-gitlab";
import { Grid } from "@material-ui/core";
import { isArgocdAvailable } from "@roadiehq/backstage-plugin-argo-cd";
import { EntityApiDocsKonfigContent, isApiDocsKonfigAvailable } from 'backstage-plugin-konfig';
import React from "react";
import { argoCDContent } from "../contents/ArgocdContent";
import { cicdContent } from "../contents/CicdContent";
import { kubernetesContent } from "../contents/KubernetesContent";
import { overviewContent } from "../contents/OverviewContent";
import { techdocsContent } from '../contents/TechdocsContent';
import { vaultContent } from "../contents/VaultContent";

export const serviceEntityPage = (
    < EntityLayout >
        < EntityLayout.Route path="/" title="Overview" >
            {overviewContent}
        </EntityLayout.Route >
        <EntityLayout.Route if={isGithubActionsAvailable} path="/ci-cd" title="CI/CD">
            {cicdContent}
        </EntityLayout.Route>
        <EntityLayout.Route if={isGitlabAvailable} path="/gitlab" title="Gitlab">
            <EntityGitlabContent />
        </EntityLayout.Route>
        <EntityLayout.Route if={isKubernetesAvailable} path="/kubernetes" title="Kubernetes">
            {kubernetesContent}
        </EntityLayout.Route>
        <EntityLayout.Route if={isArgocdAvailable} path="/argocd" title="ArgoCD">
            {argoCDContent}
        </EntityLayout.Route>
        <EntityLayout.Route if={isVaultAvailable} path="/vault" title="Vault">
            {vaultContent}
        </EntityLayout.Route>
        <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
            <EntityAdrContent />
        </EntityLayout.Route>
        <EntityLayout.Route path="/api" title="API">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <EntityProvidedApisCard />
                </Grid>
                <Grid item md={6}>
                    <EntityConsumedApisCard />
                </Grid>
            </Grid>
        </EntityLayout.Route>
        <EntityLayout.Route if={isApiDocsKonfigAvailable} path="/konfig" title="Konfig">
            <EntityApiDocsKonfigContent />
        </EntityLayout.Route>
        <EntityLayout.Route path="/dependencies" title="Dependencies">
            <Grid container spacing={3} alignItems="stretch">
                <Grid item md={6}>
                    <EntityDependsOnComponentsCard variant="gridItem" />
                </Grid>
                <Grid item md={6}>
                    <EntityDependsOnResourcesCard variant="gridItem" />
                </Grid>
            </Grid>
        </EntityLayout.Route>
        <EntityLayout.Route path="/docs" title="Docs">
            {techdocsContent}
        </EntityLayout.Route>
    </EntityLayout >
);